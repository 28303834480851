import axios from "axios";

export default {
    /**
     *
     * @param userID id of the logged user
     * @returns array of reparti
     *
     * Get logged user reparti
     */
    async getUserReparti(dipendenteID: string) {
        const data = new FormData();
        data.append("where[]", `reparti_id IN (SELECT reparti_id FROM rel_reparto_dipendenti WHERE dipendenti_id = '${dipendenteID.toString()}')`);
        data.append("orderby", "reparti_nome");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/reparti", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data;
    },
};
