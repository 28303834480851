import axios from "axios";

export default {
  /**
   * Get all reperibilità for the logged user
   * @param dipendente_id logged user id
   * @returns array with reperibilita
   */
  async getReperibilita(dipendente_id: string) {
    const formData = new FormData();
    formData.append("where[reperibilita_dipendente]", dipendente_id);
    formData.append("orderby", "reperibilita_data");
    formData.append("orderdir", "desc");

    const response = await axios.post(`rest/v1/search/reperibilita`, formData, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response.data);
    return response.data;
  },

  async setReperibilita(dipendenteId, date) {
    const data = new FormData();
    data.append("dipendente_id", dipendenteId);
    data.append("data_selezionata", date);
    //data.append("reperibile", reperibile);

    //const response = await axios.post(`http://192.168.11.134/firegui_intensione/modulo-hr/app/dichiaraReperibilita`, data, {
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/modulo-hr/app/dichiaraReperibilita`, data);

    //console.log(response);
    return response;
  },

  async deleteReperibilita(reperibilita_id) {
    const data = new FormData();
    data.append("reperibilita", reperibilita_id);

    /* const response = await axios.post(`http://192.168.11.134/firegui_intensione/modulo-hr/app/cancellaReperibilita`, data); */
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/modulo-hr/app/cancellaReperibilita`, data);

    //console.log(response);
    return response.data;
  },
};
